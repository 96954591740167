import {
  AlgoliaSearchAttempt,
  AlgoliaSearchFail,
  AlgoliaSearchSuccess,
  GetAlgoliaKeyAttempt,
  GetAlgoliaKeyFail,
} from '@/lib/ga/events/algolia'
import { AppDirBegin, AppDirDisplay } from '@/lib/ga/events/appPen'
import {
  IcoBegin,
  IcoBeginKilo,
  IcoBeginProvince,
  IcoBeginVin,
  IcoBOS,
  IcoComplete,
  IcoFaq,
  IcoGetStartedAttempt,
  IcoGetStartedCancel,
  IcoGetStartedFail,
  IcoGetStartedSuccess,
  IcoGetYourOffer,
  IcoPostAdBegin,
  IcoVinHelp,
  PmrBeginKilo,
  PmrBeginProvince,
  PmrBeginVin,
  PmrBOS,
  PmrFaq,
  PmrGetICO,
  PmrGetValueAttempt,
  PmrGetValueCancel,
  PmrGetValueFailure,
  PmrGetValueSuccess,
  PmrPostAdBegin,
  PmrVinHelp,
  ValueEstimateBegin,
  ValueEstimateComplete,
} from '@/lib/ga/events/autoCanada'
import * as CategoryEvents from '@/lib/ga/events/category'
import {
  CookieAcceptAll,
  CookieAcknowledge,
  CookieManagePreferences,
  CookiePreferenceSave,
} from '@/lib/ga/events/cookies'
import * as FilterEvents from '@/lib/ga/events/filter'
import { GoogleReviewClickAboutSeller, GoogleReviewClickR2S } from '@/lib/ga/events/googleReviews'
import * as LocationEvents from '@/lib/ga/events/location'
import { LoginBegin, LoginFail, LoginSuccess, LogoutSuccess } from '@/lib/ga/events/login'
import { MileageAnalysisLearnMore } from '@/lib/ga/events/mileageAnalysis'
import {
  KijijiMyBusinessOpen,
  MyAccountOpen,
  MyAccountSettingsOpen,
  MyAdsOpen,
  MyOrdersOpen,
} from '@/lib/ga/events/myKijiji'
import {
  PasswordResetAttempt,
  PasswordResetFail,
  PasswordResetSent,
  PasswordResetSuccess,
} from '@/lib/ga/events/passwordReset'
import { PostAdBegin } from '@/lib/ga/events/postAd'
import { PSAClick, PSADismissed, PSALoaded } from '@/lib/ga/events/psa'
import {
  SaveSearchBegin,
  SaveSearchDeleteSuccess,
  SaveSearchFail,
  SaveSearchModalCancel,
  SaveSearchModalLoad,
  SaveSearchSuccess,
} from '@/lib/ga/events/saveSearch'
import {
  ErrorStateLoad,
  ResultsBrowse,
  ResultsSearch,
  SearchAttempt,
  SearchBegin,
  SelectPromotion,
  TopAdLearnMoreClick,
  ViewPromotion,
} from '@/lib/ga/events/search'
import {
  UserActivationFail,
  UserActivationSuccess,
  UserRegistrationAttempt,
  UserRegistrationBegin,
  UserRegistrationFail,
  UserRegistrationSuccess,
} from '@/lib/ga/events/userRegistration'
import { WatchlistAdd, WatchlistOpen, WatchlistRemove } from '@/lib/ga/events/watchlist'

export type GaEvent = (typeof GA_EVENT)[keyof typeof GA_EVENT]

export const GA_EVENT = {
  ...CategoryEvents,
  ...FilterEvents,
  ...LocationEvents,
  [AlgoliaSearchAttempt]: AlgoliaSearchAttempt,
  [AlgoliaSearchFail]: AlgoliaSearchFail,
  [AlgoliaSearchSuccess]: AlgoliaSearchSuccess,
  [AppDirBegin]: AppDirBegin,
  [AppDirDisplay]: AppDirDisplay,
  [CookieAcceptAll]: CookieAcceptAll,
  [CookieAcknowledge]: CookieAcknowledge,
  [CookieManagePreferences]: CookieManagePreferences,
  [CookiePreferenceSave]: CookiePreferenceSave,
  [ErrorStateLoad]: ErrorStateLoad,
  [GetAlgoliaKeyAttempt]: GetAlgoliaKeyAttempt,
  [GetAlgoliaKeyFail]: GetAlgoliaKeyFail,
  [KijijiMyBusinessOpen]: KijijiMyBusinessOpen,
  [LoginBegin]: LoginBegin,
  [LoginFail]: LoginFail,
  [LoginSuccess]: LoginSuccess,
  [LogoutSuccess]: LogoutSuccess,
  [MyAccountOpen]: MyAccountOpen,
  [MyAccountSettingsOpen]: MyAccountSettingsOpen,
  [MyAdsOpen]: MyAdsOpen,
  [MyOrdersOpen]: MyOrdersOpen,
  [PasswordResetAttempt]: PasswordResetAttempt,
  [PasswordResetFail]: PasswordResetFail,
  [PasswordResetSent]: PasswordResetSent,
  [PasswordResetSuccess]: PasswordResetSuccess,
  [PostAdBegin]: PostAdBegin,
  [PSAClick]: PSAClick,
  [PSADismissed]: PSADismissed,
  [PSALoaded]: PSALoaded,
  [ResultsBrowse]: ResultsBrowse,
  [ResultsSearch]: ResultsSearch,
  [SaveSearchBegin]: SaveSearchBegin,
  [SaveSearchDeleteSuccess]: SaveSearchDeleteSuccess,
  [SaveSearchFail]: SaveSearchFail,
  [SaveSearchModalCancel]: SaveSearchModalCancel,
  [SaveSearchModalLoad]: SaveSearchModalLoad,
  [SaveSearchSuccess]: SaveSearchSuccess,
  [SearchAttempt]: SearchAttempt,
  [SearchBegin]: SearchBegin,
  [TopAdLearnMoreClick]: TopAdLearnMoreClick,
  [UserActivationFail]: UserActivationFail,
  [UserActivationSuccess]: UserActivationSuccess,
  [UserRegistrationAttempt]: UserRegistrationAttempt,
  [UserRegistrationBegin]: UserRegistrationBegin,
  [UserRegistrationFail]: UserRegistrationFail,
  [UserRegistrationSuccess]: UserRegistrationSuccess,
  [ValueEstimateBegin]: ValueEstimateBegin,
  [WatchlistAdd]: WatchlistAdd,
  [WatchlistOpen]: WatchlistOpen,
  [WatchlistRemove]: WatchlistRemove,
  BlockTitleClick: 'BlockTitleClick',
  BrandedCategorySelected: 'BrandedCategorySelected',
  ButtonClick: 'button_click',
  CarfaxCheckFail: 'CarProofCheckFail',
  CarfaxLearnMore: 'CarProofLearnMoreClick',
  CarfaxRequest: 'CarProofRequestSuccess',
  CarfaxRequestAttempt: 'CarProofCheckAttempt',
  CategorySelected: 'CategorySelected',
  DownloadAppClick: 'DownloadAppClick',
  GoogleReviewClickAboutSeller: GoogleReviewClickAboutSeller,
  GoogleReviewClickR2S: GoogleReviewClickR2S,
  HelpDeskClick: 'HelpDeskClick',
  HPFLoadMore: 'HPFLoadMore',
  IcoBegin: IcoBegin,
  IcoBeginKilo: IcoBeginKilo,
  IcoBeginProvince: IcoBeginProvince,
  IcoBeginVin: IcoBeginVin,
  IcoBOS: IcoBOS,
  IcoComplete: IcoComplete,
  IcoFaq: IcoFaq,
  IcoGetStartedAttempt: IcoGetStartedAttempt,
  IcoGetStartedCancel: IcoGetStartedCancel,
  IcoGetStartedFail: IcoGetStartedFail,
  IcoGetStartedSuccess: IcoGetStartedSuccess,
  IcoGetYourOffer: IcoGetYourOffer,
  IcoPostAdBegin: IcoPostAdBegin,
  IcoVinHelp: IcoVinHelp,
  KijijiCentralClick: 'KijijiCentralClick',
  MessageCenterOpen: 'MessageCenterOpen',
  MileageAnalysisLearnMore: MileageAnalysisLearnMore,
  ModalClose: 'modal_close',
  NotificationCentreOpen: 'NotificationCentreOpen',
  PageViewSwitchSuccess: 'PageViewSwitchSuccess',
  PaymentEstimatorBegin: 'payment_estimator_begin',
  PaymentEstimatorSave: 'payment_estimator_save',
  PmrBeginKilo: PmrBeginKilo,
  PmrBeginProvince: PmrBeginProvince,
  PmrBeginVin: PmrBeginVin,
  PmrBOS: PmrBOS,
  PmrFaq: PmrFaq,
  PmrGetICO: PmrGetICO,
  PmrGetValueAttempt: PmrGetValueAttempt,
  PmrGetValueCancel: PmrGetValueCancel,
  PmrGetValueFailure: PmrGetValueFailure,
  PmrGetValueSuccess: PmrGetValueSuccess,
  PmrPostAdBegin: PmrPostAdBegin,
  PmrVinHelp: PmrVinHelp,
  POAClick: 'POAClick',
  PriceDropDismiss: 'pricedrop_dismiss',
  R2SEmailAttempt: 'R2SEmailAttempt',
  R2SEmailBegin: 'R2SEmailBegin',
  R2SEmailFail: 'R2SEmailFail',
  R2SEmailSuccess: 'R2SEmailSuccess',
  R2SExternalBegin: 'R2SExternalBegin',
  R2SPhoneBegin: 'R2SPhoneBegin',
  R2SPhoneReveal: 'R2SPhoneReveal',
  RecentAdClick: 'RecentAdClick',
  RecommendationAdClick: 'RecommendationAdClick',
  RecommendedAdClick: 'RecommendedAdClick',
  RecommendedForYouDisplayed: 'RecommendedDisplayed',
  RelatedKeywordClick: 'RelatedKeywordClick',
  ReportAdAttempt: 'ReportAdAttempt',
  ReportAdFail: 'ReportAdFail',
  ReportAdSuccess: 'ReportAdSuccess',
  RequestViewingAttempt: 'RequestViewingAttempt',
  RequestViewingBegin: 'RequestViewingBegin',
  RequestViewingCancel: 'RequestViewingCancel',
  RequestViewingFail: 'RequestViewingFail',
  RequestViewingSuccess: 'RequestViewingSuccess',
  ResultsAdClick: 'ResultsAdClick',
  ResultsImpressions: 'ResultsImpressions',
  SelectPromotion: SelectPromotion,
  ShareAdBegin: 'ShareAdBegin',
  SwitchLanguage: 'SwitchLanguage',
  ValueEstimateComplete: ValueEstimateComplete,
  ViewImageClick: 'ViewLargerImageClick',
  ViewMapClick: 'ViewMapClick',
  ViewPromotion: ViewPromotion,
  ViewUserRatingsClick: 'ViewUserRatingsClick',
  ViewUsersOtherAds: 'ViewUsersOtherAds',
  ViewVideoClick: 'ViewVideoClick',
  ViewVirtualTourClick: 'ViewVirtualTourClick',
  WantedSelected: 'WantedSelected',
} as const

export const GA_EVENT_LABEL = {
  POPULAR_NEAR_YOU: 'popular_for_you',
}
