import { type ServerConfig, type ValueSource } from 'firebase-admin/remote-config'

export type TrackingKey = `kjca${string}`

export type ToggleParamKey = `toggle_${string}`
type ToggleValue = boolean

export type ExperimentParamKey = `exp_${string}`
type ExperimentValue = ExperimentValues

export type ConfigParamKey = `config_${string}`
type ConfigValue = string

export type EnvParamKey = `env_${string}`
type EnvValue = string | number

interface RemoteParameter<K, V> {
  key: K
  defaultValue?: { int: V; prod: V }
}

interface RemoteParameterOptionalTracking<K, D> extends RemoteParameter<K, D> {
  trackingKey?: TrackingKey
}

export interface RemoteParameterRequiredTracking<K, D> extends RemoteParameter<K, D> {
  trackingKey: TrackingKey
}

export enum ExperimentValues {
  GROUP_A = 1,
  GROUP_B = 2,
  CONTROL_GROUP = 0,
  EVERYONE_ELSE = -1,
  INTERNAL_ON = -2,
}

export type RemoteToggleType = {
  [key: string]: RemoteParameterOptionalTracking<ToggleParamKey, ToggleValue>
}
export type RemoteExperimentType = {
  [key: string]: RemoteParameterRequiredTracking<ExperimentParamKey, ExperimentValue>
}
export type RemoteConfigType = {
  [key: string]: RemoteParameter<ConfigParamKey, ConfigValue>
}
export type RemoteEnvType = {
  [key: string]: RemoteParameter<EnvParamKey, EnvValue>
}

export type FirebaseConfigType = Record<
  string,
  RemoteConfigType | RemoteExperimentType | RemoteToggleType | RemoteEnvType
>

export type FirebaseOverrides = {
  [key: ToggleParamKey]: ToggleValue // Keys starting with "toggle_" must have boolean values
  [key: EnvParamKey]: EnvValue // Keys starting with "env_" must have number values
  [key: ExperimentParamKey]: ExperimentValue // Keys starting with "toggle_" must have boolean values
  [key: ConfigParamKey]: ConfigValue // Keys starting with "env_" must have number values
}

export interface ValueImpl {
  value: string
  source: ValueSource
}

export interface ServerConfigImpl extends ServerConfig {
  configValues: { [key: string]: ValueImpl }
}

export interface Toggle {
  enabled: ToggleValue
  source: ValueSource
  trackingKey: TrackingKey | null
}

export interface Experiment {
  enabled: ToggleValue | null
  source: ValueSource
  trackingKey: TrackingKey
  group: ExperimentValue
}

export interface Config {
  data: { [key: string]: unknown } | ConfigValue // JSON object
  source: ValueSource
}

export interface Env {
  value: EnvValue
  source: ValueSource
}

export interface FirebaseConfig {
  configs: Record<string, Config>
  envs: Record<string, Env>
  experiments: Record<string, Experiment>
  toggles: Record<string, Toggle>
}

export interface ServiceAccount {
  clientEmail: string
  projectId: string
  privateKey: string
}

export type ExtractKeys<T extends Record<string, { key: string }>> = {
  [K in keyof T]: T[K]['key']
}
