import { type ReactNode, createContext, useMemo } from 'react'

import {
  type Config,
  type ConfigParamKey,
  type Env,
  type EnvParamKey,
  type Experiment,
  type ExperimentParamKey,
  type FirebaseConfig,
  type Toggle,
  type ToggleParamKey,
  type TrackingKey,
} from '@/lib/firebase/types'

interface ParamsContextValue {
  getToggle: (key: ToggleParamKey) => Toggle | undefined
  getConfig: (
    key: ConfigParamKey
  ) => (Omit<Config, 'data'> & { data: { [key: string]: unknown } }) | undefined
  getEnv: (key: EnvParamKey) => Env | undefined
  getExperiment: (key: ExperimentParamKey) => Experiment | undefined
  getAllTracked: () => Array<{ key: TrackingKey; value: number }>
}

interface RemoteConfigProviderProps {
  config?: FirebaseConfig
  children: ReactNode
}
export const ParamsContext = createContext<ParamsContextValue | undefined>(undefined)

export const RemoteConfigProvider: React.FC<RemoteConfigProviderProps> = ({ config, children }) => {
  const context = useMemo(() => {
    const { toggles, configs, envs, experiments } = config ?? {}

    const getEnv = (key: EnvParamKey) => envs?.[key]
    const getExperiment = (key: ExperimentParamKey) => experiments?.[key]
    const getToggle = (key: ToggleParamKey) => toggles?.[key]
    const getConfig = (key: ConfigParamKey) => {
      const config = configs?.[key]

      if (!config) return undefined

      try {
        const { data: serializedData, ...rest } = config
        const data =
          typeof serializedData === 'string' ? JSON.parse(serializedData) : serializedData
        return data ? { data, ...rest } : undefined
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        console.warn(`Remote config ${key} contained invalid JSON data: ${e}`)
        return undefined
      }
    }
    const getAllTracked = () => {
      const trackedExperiments =
        (experiments &&
          Object.values(experiments).reduce(
            (acc, exp) => {
              if (!exp.trackingKey || !exp.enabled) return acc
              return [...acc, { key: exp.trackingKey, value: exp.group }]
            },
            [] as Array<{ key: TrackingKey; value: number }>
          )) ??
        []

      const trackedToggles =
        (toggles &&
          Object.values(toggles).reduce(
            (acc, tog) => {
              if (!tog.trackingKey) return acc
              return [...acc, { key: tog.trackingKey, value: tog.enabled ? 1 : 0 }]
            },
            [] as Array<{ key: TrackingKey; value: number }>
          )) ??
        []

      return [...trackedExperiments, ...trackedToggles]
    }

    return {
      getToggle,
      getConfig,
      getEnv,
      getExperiment,
      getAllTracked,
    }
  }, [config])

  return <ParamsContext.Provider value={context}>{children}</ParamsContext.Provider>
}
